import { navigate } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import PageLoader from 'components/UI/PageLoader'
import { useCurrentUserState } from 'state/CurrentUserState'
import { TokenStorage } from 'lib/TokenStorage'

type MessageEvent = { type: string; data?: any }

export const isApp = () => {
  return sessionStorage.getItem('APP') === 'true'
}

const useParentMessageEvents = (onMessage: (e: MessageEvent) => void) => {
  const postMessage = (type: string, data?: any) => {
    window.parent.postMessage({ type, data }, '*')
  }

  const handleWindowMessages = (event: { data: MessageEvent }) => {
    if (event.data?.type) {
      onMessage(event.data)
    }
  }

  const listen = () => {
    window.addEventListener('message', handleWindowMessages, false)
  }

  const cleanup = () => {
    window.removeEventListener('message', handleWindowMessages, false)
  }

  useEffect(() => {
    listen()
    return () => {
      cleanup()
    }
  }, [])

  return postMessage
}

const EmbedPage = () => {
  const onTokenCallbackRef = useRef<() => {} | null>(null)
  const { onLoginSuccess } = useCurrentUserState()

  const handleToken = async (token: {
    user: any
    accessToken: { value: string; expiresAt: Date }
    refreshToken: { value: string; expiresAt: Date }
  }) => {
    await TokenStorage.setItem('token', {
      value: token.accessToken.value,
      expiresAt: token.accessToken.expiresAt,
    })

    const t = await TokenStorage.getItem('token')

    if (t && t.value && new Date(t.expiresAt) > new Date()) {
      await onLoginSuccess()
      if (onTokenCallbackRef.current) {
        onTokenCallbackRef.current()
      }
    }
  }

  const handleMessage = (message: MessageEvent) => {
    if (message.type === 'TOKEN' && message.data?.token?.accessToken) {
      handleToken(message.data.token)
    }
  }

  const postMessage = useParentMessageEvents(handleMessage)

  const requestToken = () => {
    postMessage('GET_TOKEN')
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const source = params.get('source')
    if (source === 'APP') {
      sessionStorage.setItem('APP', 'true')
      // @ts-expect-error Ignore
      onTokenCallbackRef.current = () => {
        const redirectTo = params.get('redirectUrl') || '/'

        navigate(redirectTo)
      }
      requestToken()
    }
  }, [])

  if (typeof window === 'undefined') return null

  return <PageLoader color="#ffffff" size={90} />
}

const EmbedPageWrapper = () => {
  if (typeof window === 'undefined') return null
  return <EmbedPage />
}

export default EmbedPageWrapper
